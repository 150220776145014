#projects__container {
  width: 95%;
  max-width: 900px;
  background-color: #ddd;
  border: 1px solid black;
  border-radius: 50px;
  margin: 0 20px 20px 20px;
  display: flex;
  margin: 0 auto 10px auto;
}
.carousel {
  position: relative;
  height: 670px;
  width: 80%;
  margin: 0 auto;
  align-self: center;
}
.carousel__track-container {
  height: 635px;
  position: relative;
  overflow: hidden;
}
.carousel__track {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  height: 100%;
  transition: transform 250ms ease-in;
}
.carousel__slide {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}
.carousel__text__container {
  text-align: center;
  width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.project-title {
  font-size: 1.7rem;
  display: inline;
  line-height: 0;
}
.project-description {
  font-size: 1.3rem;
}
.video-container {
  display: flex;
  justify-content: center;
}
.video-container video {
  margin: 20px 0 30px 0;
}
.links-container {
  display: flex;
  justify-content: space-evenly;
  gap: 15px;
}
.project-link {
  font-size: 1.9rem;
  flex-grow: 1;
}
.project-github {
  font-size: 1.9rem;
  flex-grow: 1;
}
@media screen and (max-width: 800px) {
  .carousel__text__container {
    width: 95%;
  }
  .carousel__text {
    font-size: 1.3rem;
  }
  .project-link {
    font-size: 1.5rem;
  }
  .project-github {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 520px) {
  .carousel__text {
    font-size: 1rem;
  }
}
.carousel__header {
  display: inline;
}
.carousel__button {
  cursor: pointer;
}
.carousel__button--left {
  left: -40px;
}
.carousel__button--right {
  right: -40px;
}
.carousel__button img {
  width: 25px;
}
.carousel__nav {
  display: flex;
  justify-content: center;
  padding: 10px 0;
}
.carousel__indicator {
  border: 0;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  background: rgba(0, 0, 0, 0.3);
  margin: 0 10px;
  cursor: pointer;
}
.carousel__indicator.current-slide {
  background: rgba(0, 0, 0, 0.75);
}
